<template>
  <div>
    <div
      style="
        width: 100%;
        height: 70px;
        padding-left: 15px;
        line-height: 70px;
        position: relative;
      "
    >
      <img
        src="@/assets/logo.png"
        style="width: 35px; position: absolute; top: 28%"
      />
      <h1 class="title" v-if="!fold">本地合美乡村</h1>
    </div>
    <el-menu
      :unique-opened="true"
      router
      :collapse="fold"
      :default-active="myPath"
      class="el-menu-vertical-demo"
      background-color="#373a3d"
      text-color="rgb(191, 203, 217)"
      active-text-color="rgb(64, 158, 255)"
      :collapse-transition="false"
    >
      <template v-for="route in constantRoutes">
        <el-submenu
          v-if="route.childMenus"
          :index="route.id.toString()"
          :key="route.id"
        >
          <template slot="title">
            <i :class="getIcon(route.icon)"></i>
            <span>{{ route.title }}</span>
          </template>
          <el-menu-item
            :index="child.jump"
            v-for="child in route.childMenus"
            :key="child.jump"
          >
            <i :class="getIcon(child.icon)"></i>
            {{ child.title }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="route.jump" :key="route.jump">
          <i :class="getIcon(route.icon)"></i>
          <span slot="title">{{ route.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<style lang="less" scoped>
@menuBackgroundColor: #373a3d;
@textColor: rgb(191, 203, 217);
@activeTextColor: rgb(64, 158, 255);

.title {
  line-height: 70px;
  height: 50px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 65px;
}
.el-submenu .el-menu-item {
  background-color: #282929 !important;

  &:hover {
    background-color: #000000 !important;
  }
}
::v-deep .is-active > .el-submenu__title {
  color: #fff !important;
}
.el-submenu__title {
  font-size: 18px !important;

  .el-menu {
    background-color: #2783d8 !important;
  }
}
</style>
  
<script>
export default {
  props: ["fold"],
  data() {
    return {
      myPath: this.$route.path,
      constantRoutes: [],
      cindex: null,
    };
  },
  computed: {},
  watch: {
    $route: function (to, from) {
      this.menuPath(to, from);
      sessionStorage.setItem("previousPath", from.path);
    },
  },
  async created() {
    try {
      await this.getmenu();
      // this.menuPath();
      // loading.close();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    menuPath(to, from) {
      if (to) this.myPath = to?.path;
      if (this.$route.meta && this.$route.path) {
        let meu = this.constantRoutes.some((e) => e.jump === this.myPath);
        if (meu) {
          this.myPath = this.$route.path;
          sessionStorage.removeItem("previousPath");
        } else {
          let nenuCher = this.constantRoutes.some((i) =>
            i?.childMenus?.some((e) => e.jump === this.myPath)
          );
          if (nenuCher) {
            this.myPath = this.$route.path;
          } else {
            let path = sessionStorage.getItem("previousPath");
            this.myPath = from?.path || path;
          }
        }
      }
    },
    async getmenu() {
      try {
        const { data, status } = await this.$api.setup.menuBar({ platform: 2 });
        if (status) {
          this.constantRoutes = data?.data;
          localStorage.setItem("routeList", JSON.stringify(data?.data));
        } else {
          console.error("Failed to fetch menu data:", data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getIcon(icon) {
      return icon ? icon : "el-icon-minus";
    },
  },
};
</script>