import axios from '../request/http';

const dataSettings = {
    // 获取乡村资料详情
    getInfoDetail(params) {
        return axios.get("/hemei-admin/villageBaseInfo/detail", { params });
    },
    // 修改乡村资料
    updVillageBaseInfo(data) {
        return axios.post("/hemei-admin/villageBaseInfo/updVillageBaseInfo", data);
    },
}

export default dataSettings;