import axios from '../request/http';
import {toFormData} from '../common/descriptions'

const government = {
    // 获取组织成员列表
    getVillagePartyMemberList(params) {
        return axios.get("/hemei-admin/villageParty/getVillagePartyMemberList", { params });
    },
    // 获取党组织信息
    getVillagePartyInfo(params) {
        return axios.get("/hemei-admin/villageParty/getVillagePartyInfo", { params });
    },
    // 添加党组织成员
    addVillagePartyMember(data) {
        return axios.post("/hemei-admin/villageParty/addVillagePartyMember", data);
    },
    // 修改/新增党组织结构信息
    addOrUpdVillageParty(data) {
        return axios.post("/hemei-admin/villageParty/addOrUpdVillageParty", toFormData(data));
    },
    // 删除党组织成员
    delVillagePartyMember(data) {
        return axios.post("/hemei-admin/villageParty/delVillagePartyMember", toFormData(data));
    },
    // 清空重置信息
    resetVillageParty(data) {
        return axios.post("/hemei-admin/villageParty/resetVillageParty", data);
    },
    // 获取办事服务列表
    getVillageOfficeServicePage(params) {
        return axios.get("/hemei-admin/villageOfficeService/getVillageOfficeServicePage", { params });
    },
    // 新增、修改办事服务
    addOrUpdVillageOfficeService(data) {
        return axios.post("/hemei-admin/villageOfficeService/addOrUpdVillageOfficeService", data);
    },
    // 删除办事服务
    delVillageOfficeService(data) {
        return axios.post("/hemei-admin/villageOfficeService/delVillageOfficeService", toFormData(data));
    },
}

export default government;