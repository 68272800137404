export default {
   // api: "http://192.168.20.206:8700/",
   // 线上
    api: " https://village.hemeixiangcun.xn--fiqs8s",
   // api: "http://192.168.2.9:8700",
   // api:'https://village.hemeixiangcun.xn--fiqs8s',
   //  api: "http://192.168.0.132:8087",
    // 测试地址
   //  api: "https://test.aiteyixia.cn",
    // 生产地址
   // api: "https://yuhou.aiteyixia.cn/"

}
