import axios from '../request/http';

const Login = {
   // 获取验证码
   getCode() {
    return axios.get("/hemei-admin/login/captcha");
},
// 登录
login(params) {
    return axios.get("/hemei-admin/login/login", {params});
},
}

export default Login;