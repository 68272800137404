import Layout from '@/layout'
const governmentRouter = {
    component: Layout,
    redirect: '/organization',
    path: '/organization',
    name: '党政活动',
    meta: {
        title: '党政活动',
        icon: ''
    },
    children: [
        {
            path: '/organization',
            name: 'organization',
            meta: { title: "村党组织结构" },
            component: () => import('../views/government/organization/organization.vue')
        },
        {
            path: "/workService",
            name: "workService",
            meta: { title: "办事服务" },
            component: () => import('../views/government/workService/service.vue')
        },
    ]

}
export default governmentRouter
