import Vue from 'vue'
import Vuex from 'vuex'
import persist from 'vuex-persistedstate'
import tagsView from './tagsView'

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    // 路由列表
    routeList: [],
    // 账号时间
    time: '',
    // 角色信息
    route: '',
    // 按钮列表
    pageButton: [],
    // 商户服务
    businServeID: [],
    // 顶部高度
    headerHeight: '',
    // 标签页高度
    barHeight: '',
  },
  getters: {
    visitedViews: state => state.tagsView.visitedViews,

  },
  mutations: {
    // // 保存路由
    // saveRoute(state, e) {
    //   state.routeList = e
    // },
    // // 易企的状态更改
    // saveTime(state, e){
    //   state.time = e
    // },
    // // 储存角色权限ID
    // saveRouteID(state, e) {
    //   state.route = e
    // },
    // // 存储页面按钮权限
    // savePageButton(state, e){
    //   state.pageButton = e
    // },
    // // 存储商户服务ID
    // saveBusinServe(state, e) {
    //   state.businServeID = e
    // },
    // // 储存顶部高度
    // saveHeaderHeight(state, e) {
    //   state.headerHeight = e
    // },
    // // 储存标签页高度
    // saveBarHeight(state, e) {
    //   state.barHeight = e
    // }
  },
  actions: {

  },
  modules: {

    tagsView,
  
  },
  plugins: [
    new persist({
      storage: window.localStorage,
    }),
  ],
})
