import Layout from '@/layout'
const dataSettingRouter = {
    component: Layout,
    path: '/',
    name: '乡村资料设置',
    redirect: '/dataSettings',

    meta: {
        title: '乡村资料设置',
        icon: '',
        keepAlive: true, // 需要被缓存
    },
    children: [
        {
            path: '/dataSettings',
            name: 'dataSettings',
            meta: { title: "乡村资料设置" },
            component: () => import('../views/dataSettings/dataSettings.vue')
        },
    ]

}
export default dataSettingRouter
