import Layout from '@/layout'
const localServiceRouter = {
    component: Layout,
    path: '/localService',
    name: '乡村本地服务管理',
    redirect: '/localService',

    meta: {
        title: '乡村本地服务管理',
        icon: ''
    },
    children: [
        {
            path: '/localService',
            name: 'localService',
            meta: { title: "乡村本地服务管理" },
            component: () => import('../views/localService/localService.vue')
        }
    ]

}
export default localServiceRouter
