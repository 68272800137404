/**
 * api接口的统一出口
*/
import Login from '@/api/Login';
import apply from '@/api/apply';
import ecommerce from '@/api/ecommerce';
import setup from '@/api/setup';
import dataSettings from '@/api/dataSettings';
import government from '@/api/government';

export default {
    Login,
    apply,
    ecommerce,
    setup,
    dataSettings,
    government
}
