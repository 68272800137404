import Layout from '@/layout'
const ECommerceServiceRouter = {
    component: Layout,
    path: '/commerceData',
    redirect: '/commerceData',
    name: '乡村电商服务',
    meta: {
        title: '乡村电商服务',
        icon: ''
    },
    children: [
        {
            path: '/commerceData',
            name: 'commerceData',
            meta: { title: "乡村电商数据展示" },
            component: () => import('../views/ECommerceService/commerceData/commerceData.vue')
        },
        {
            path: "/applyActivation",
            name: "applyActivation",
            meta: { title: "申请开通乡村" },
            component: () => import('../views/ECommerceService/applyActivation/applyActivation.vue')
        },
    ]

}
export default ECommerceServiceRouter
