import Layout from '@/layout'
const localityServeRouter = {
    component: Layout,
    path: '/localityServe',
    name: '本地乡村服务',
    redirect: '/phoneBook',

    meta: {
        title: '本地乡村服务',
        icon: ''
    },
    children: [
        {
            path: '/phoneBook',
            name: 'phoneBook',
            meta: { title: "乡村电话本" },
            component: () => import('../views/localityServe/phoneBook/list.vue')
        },
        {
            path: "/neighbourhood",
            name: "neighbourhood",
            meta: { title: "乡村邻里服务需求信息" },
            component: () => import('../views/localityServe/neighbourhood/list.vue')
        },
        {
            path: "/culture",
            name: "culture",
            meta: { title: "乡村文化" },
            component: () => import('../views/localityServe/culture/list.vue')
        },
        {
            path: "/addCulture",
            name: "addCulture",
            meta: { title: "添加乡村文化" },
            component: () => import('../views/localityServe/culture/addCulture.vue')
        },
    ]

}
export default localityServeRouter
