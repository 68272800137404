import axios from '../request/http';
import {toFormData} from '../common/descriptions'

const ecommerce = {
    // 乡村电商数据展示
    getVillageEcommerceData(params) {
        return axios.get("/hemei-admin/villageEcommerce/getVillageEcommerceData", { params });
    },
    // 获取申请乡村电商开通展示信息
    getAddVillageEcommerceInfo(params) {
        return axios.get("/hemei-admin/villageEcommerce/getAddVillageEcommerceInfo", { params });
    },
    // 申请乡村电商开通信息
    addVillageEcommerce(data) {
        return axios.post("/hemei-admin/villageEcommerce/addVillageEcommerce", toFormData(data));
    },
    // 乡村月销售额
    getSalesVolume(params) {
        return axios.get("/hemei-admin/villageEcommerce/month/salesVolume", { params });
    },
}

export default ecommerce;