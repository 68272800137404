import Layout from '@/layout'
const articleRouter = {
  component: Layout,
  path: '/article',
  name: '乡村文章列表',
  redirect: '/article',
  meta: {
    icon: '',
    keepAlive: true, // 需要被缓存
  },
  children: [
    {
        path: "/article",
        name: "article",
        meta: { title: "乡村文章列表" },
        component: () => import('../views/article/list.vue')
    },
  ]
}
export default articleRouter