import Layout from '@/layout'
const setUserRouter = {
  component: Layout,
  path: '/management',
  name: '系统设置',
  redirect: '/management',
  meta: {
    title: '设置',
    icon: ''
  },
  children: [
    {
      path: "/management",
      name: "management",
      meta: { title: "系统账号管理" },
      component: () => import("../views/management/management.vue")
    },
    {
      path: "/routeManagement",
      name: "routeManagement",
      meta: { title: "路由管理" },
      component: () => import("../views/management/routeManagement.vue")
    },
  ]

}
export default setUserRouter
